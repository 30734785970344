import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';

import { PagesService } from '../state/pages.service';

@Injectable({
  providedIn: 'root',
})
export class PagesGuard extends SyncGuard {
  constructor(protected service: PagesService) {
    super();
  }

  sync(params: Params) {
    const { schoolId } = params;

    this.service.resetStore();

    return this.service.sync(schoolId);
  }
}
